import React, { useState, useEffect } from "react";
import { Box, DataGrid, DateBox } from "devextreme-react";
import { Item } from "devextreme-react/box";
import { Column, Paging, Export, Pager, Editing  } from "devextreme-react/data-grid";

import http from "../../../utils/http";
import Title from "../../../components/shared/Title";
import BlockHeader from "../../../components/shared/BlockHeader";
import { cellRender, cellRenderBold } from "../../../utils/common";
import { formatDate } from "../../../data/app";
import useAuthorization from '../../../hooks/useAuthorization';
import { resources } from '../../../data/app';

const StockByDate = (props) => {

    const [ authorized ] = useAuthorization( resources.verKardex );
    const [date, setDate] = useState(null);    
    const [kardex, setKardex] = useState(undefined); 
    const [loading, setLoading] = useState(false);

    const changeDate = e => {
        setDate(e.value)
    }

    const loadData =  async()=>{
        if(date){
            setKardex([]);
            setLoading(true);
            const resp = await http('reports/StocksByDate').asPost({ date });          
            setKardex(resp);
            setLoading(false);

        }              
    }

    useEffect(() => {
        loadData();
    }, [date]);       

    const title ='Existencias por fecha';    

    return authorized(
        <div className="container big">
            <Title title={title} />
            <BlockHeader title={title} showFavorite={true} {...props} />            

            <Box direction="row" width="20%" height={75}>                           
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Seleccione la fecha de corte:</label>
                        <DateBox type="date" displayFormat={formatDate} openOnFieldClick={true} onValueChanged={changeDate}/>
                    </div>
                </Item>
            </Box>
            { loading && <div className="text-center">Cargando datos...</div> }
            <Box direction="row" width="100%" height={75}>

                <Item ratio={1}>
                    <label>Reporte de existencias por fecha</label>                   
                    <DataGrid

                        id="gridContainer"
                        className="vgridContainer"
                        selection={{ mode: 'single' }}
                        dataSource={kardex}
                        showBorders={true}
                        showRowLines={true}
                        allowColumnResizing={true}
                        hoverStateEnabled={true}
                    >                       
                        <Paging defaultPageSize={10} />
                        <Pager
                            showInfo={true}
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 20, 50,100,500]}
                        />
                        <Export enabled={true} fileName={title} allowExportSelectedData={true} /> 
                        <Column dataField="internalCode" caption="Código" width={90} />
                        <Column dataField="location" caption="Ubicación" width={90} />
                        <Column dataField="providerName" caption="Proveedor" width={90} />
                        <Column dataField="reference" caption="Factura" width={90} />
                        <Column dataField="name" caption="Nombre"  />
                        <Column dataField="categoryName" caption="Categoria" width={110} />
                        <Column dataField="marcName" caption="Marca" width={110} />
                        <Column dataField="um" caption="UM" width={110} />
                      
                        <Column dataField="stocks" caption="Cantidad"  width={75}/>
                        <Column dataField="cost" caption="Costo" cellRender={cellRender()} width={100} alignment="right" />
                        <Column dataField="price" caption="Precio" cellRender={cellRender()} width={100} alignment="right" />
                        <Column dataField="total" caption="Total" cellRender={cellRenderBold()} width={115} alignment="right"/>
                        
                        <Editing
                            mode="popup"
                            allowUpdating={false}                           
                            useIcons={true}
                        >
                        </Editing>                    
                    </DataGrid>                                   
                </Item>              
            </Box>    
        </div>
    );
}

export default StockByDate;
